var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('create-ui',{attrs:{"title":"Ajout d'un utilisateur","loading":_vm.loading},on:{"close":function($event){return _vm.$emit('close')},"create":_vm.create},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Nom et Prénom","rules":[
            (v) =>
              !!v || 'Ce champ est requis pour ajouter un nouvel utilisateur',
          ]},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})],1),_c('v-col',[_c('society-autocomplete',{model:{value:(_vm.societyId),callback:function ($$v) {_vm.societyId=$$v},expression:"societyId"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Numéro de badge (6 chiffres)","rules":[
            (v) =>
              (v != null && v.toString().length > 0) || 'Ce champ est requis',
            (v) => _vm.isInteger(v) || 'Saisir un nombre entier',
            (v) =>
              (!!v && v.length == 6) ||
              'Le numéro de badge doit contenir exactement 6 chiffres',
            (v) => !_vm.isAllowed(v) || 'Le numéro de badge est déjà attribué',
          ]},model:{value:(_vm.badgeNumber),callback:function ($$v) {_vm.badgeNumber=$$v},expression:"badgeNumber"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Adresse mail"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-subheader',[_vm._v("Droits")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-checkbox',{attrs:{"disabled":!_vm.currentUser.isSuperAdministrator,"label":"Administrateur"},model:{value:(_vm.isAdministrator),callback:function ($$v) {_vm.isAdministrator=$$v},expression:"isAdministrator"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Accès TechBDD - Gestion de la BDD"},model:{value:(_vm.canAccessDb),callback:function ($$v) {_vm.canAccessDb=$$v},expression:"canAccessDb"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Accès TechBDD - Gestion des RE"},model:{value:(_vm.canAccessExamReport),callback:function ($$v) {_vm.canAccessExamReport=$$v},expression:"canAccessExamReport"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Accès TechMEP"},model:{value:(_vm.canAccessMeasureApp),callback:function ($$v) {_vm.canAccessMeasureApp=$$v},expression:"canAccessMeasureApp"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('date-selector',{attrs:{"is-clearable":true,"label":"Date de départ"},model:{value:(_vm.leavingDate),callback:function ($$v) {_vm.leavingDate=$$v},expression:"leavingDate"}})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }