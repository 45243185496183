
import {
  Component,
  Vue,
  Inject,
  Prop,
  InjectReactive,
} from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { lightFormat } from "date-fns";

import { PROVIDER, SERVICES as S, USER } from "@/config/literals";
import { isInteger } from "@/tools";

import { DateSelector } from "prometheus-synced-ui";
import { UserDetails } from "@/domain";

import CreateUi from "@/components/Core/Create/CreateUI.vue";
import SocietyAutocomplete from "@/components/General/Autocompletes/SocietyAutocomplete.vue";

import { IUserService } from "@/services";

@Component({
  components: {
    CreateUi,
    SocietyAutocomplete,
    DateSelector,
  },
  data: () => {
    return {
      isInteger,
    };
  },
})
export default class UserCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @InjectReactive(USER)
  currentUser!: UserDetails;

  @Prop({ required: true })
  forbiddenBadges!: string[];

  get userService() {
    return this.container.resolve<IUserService>(S.USER_SERVICE);
  }

  loading: boolean = false;
  fullName: string | null = null;
  societyId: string | null = null;
  badgeNumber: string | null = null;
  email: string | null = null;
  isAdministrator: boolean = false;
  canAccessDb: boolean = false;
  canAccessExamReport: boolean = false;
  canAccessMeasureApp: boolean = false;
  leavingDate: Date | null = null;

  async create() {
    try {
      this.loading = true;
      const userCreated = await this.userService.create({
        fullName: this.fullName!,
        societyId: this.societyId!,
        badgeNumber: this.badgeNumber,
        email: this.email,
        isAdministrator: this.isAdministrator,
        canAccessDb: this.canAccessDb,
        canAccessExamReport: this.canAccessExamReport,
        canAccessMeasureApp: this.canAccessMeasureApp,
        leavingDate:
          this.leavingDate != null
            ? lightFormat(this.leavingDate, "yyyy-MM-dd")
            : null,
      });
      this.$emit("create", userCreated);
    } finally {
      this.loading = false;
    }
  }

  isAllowed(badge: string) {
    return _.findIndex(this.forbiddenBadges, (bNo) => bNo == badge) != -1;
  }
}
